import React, { useEffect, useState } from "react";
import {
  List,
  DateField,
  Datagrid,
  TextField,
  EmailField,
  Filter,
  TextInput,
  useListContext,
} from "react-admin";
import { Box } from "@material-ui/core";
import ops from "../operations";

import OnlyTextField from "./OnlyTextField";

const UserFilter = (props) => {
  const { setFilters } = useListContext();

  useEffect(() => {
    setFilters({});
  }, []);

  return (
    <Filter {...props}>
      <TextInput label="Cerca" source="q" alwaysOn />

      {/* <ReferenceInput label="Nome" source="nome" reference="patients" allowEmpty>
      <SelectInput optionText="nome" />
    </ReferenceInput> */}
    </Filter>
  );
};

function calculate_age(dob) {
  var diff_ms = Date.now() - new Date(dob).getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

const AgeField = ({ record, source }) => (
  <span style={styles.small}>{calculate_age(record[source])}</span>
);

const PatientsList = (props) => {
  const [patientsCount, setPatientsCount] = useState(0);

  useEffect(() => {
    async function GetPatientsCountAsync() {
      let count = await ops.getPatientsCount();
      // console.log(count);
      setPatientsCount(count);
    }
    GetPatientsCountAsync();
  }, [patientsCount]);

  return (
    <React.Fragment>
      {patientsCount > 0 && (
        <h3
          style={{
            position: "absolute",
            top: "6rem",
            left: "3.5rem",
          }}
        >
          {patientsCount}
        </h3>
      )}
      <List {...props} filters={<UserFilter />} perPage={25}>
        <Datagrid rowClick="edit">
          <TextField source="nome" style={{ fontWeight: "bolder" }} />
          <TextField source="cognome" style={{ fontWeight: "bolder" }} />
          <AgeField source="birthday" label="Anni" />
          <DateField
            source="created_at"
            label="Prima visita"
            style={styles.small}
          />
          <DateField
            source="updated_at"
            label="Ultima visita"
            style={styles.small}
          />
          <TextField
            source="cellphone"
            label="Cellulare"
            style={styles.medium}
          />
          <TextField source="taxCode" label="C. Fiscale" />
          <TextField source="address" label="Indirizzo" />
          <OnlyTextField
            source="diagnosys"
            label="Diagnosi"
            style={styles.small}
          />
          {/* <TextField source="horoscopes" /> */}
          {/* <TextField source="updates" /> */}
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

const styles = {
  small: {
    fontSize: "14px",
  },
  medium: {
    fontSize: "16px",
  },
};

export default PatientsList;
