import * as React from "react";
import PropTypes from "prop-types";

const OnlyTextField = ({ source, record, style = {} }) => (
  <React.Fragment>
    {record[source] && (
      <span style={style}>{record[source].replace(/<[^>]*>?/gm, "")}</span>
    )}
  </React.Fragment>
);

OnlyTextField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default OnlyTextField;
