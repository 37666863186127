import React, { useEffect } from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  Toolbar,
  SaveButton,
  DeleteButton,
  DateField,
} from "react-admin";

import RichTextInput from "ra-input-rich-text";

import ops from "../../operations";

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton redirect={ops.previousRoute} />
    <DeleteButton undoable={true} />
  </Toolbar>
);

export const AnamnesyEdit = (props) => {
  useEffect(() => {
    console.log(props);
  }, []);

  return (
    <Edit {...props}>
      <TabbedForm toolbar={<CustomToolbar />} submitOnEnter={false}>
        <FormTab label="Info">
          <DateField source="published_at" />
          <DateField source="created_at" />
          <DateField source="updated_at" />
        </FormTab>

        <FormTab label="Anamnesi Familiare">
          <RichTextInput source="familiarAn" />
        </FormTab>
        <FormTab label="Anamnesi Fisiologica">
          <RichTextInput source="physioAn" />
        </FormTab>
        <FormTab label="Anamnesi Somatica">
          <RichTextInput source="somatizedAn" />
        </FormTab>
        <FormTab label="Anamnesi Psichiatrica Remota">
          <RichTextInput source="psychiatricAnRemote" />
        </FormTab>
        <FormTab label="Anamnesi Psichiatrica Prossima">
          <RichTextInput source="psychiatricAnProximal" />
        </FormTab>
        <FormTab label="Terapia Corrente">
          <RichTextInput source="currentTherapy" />
        </FormTab>
        <FormTab label="Percezioni">
          <RichTextInput source="perceptions" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default AnamnesyEdit;
