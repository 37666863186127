import React, { useState, useEffect } from "react";
import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  BooleanInput,
} from "react-admin";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ReactHtmlParser from "react-html-parser";
import RichTextInput from "ra-input-rich-text";

import { withRouter } from "react-router-dom";
import ops from "../../operations";

//va aggiunto un campo in sola lettura della terapia precedente, qualora esistente

//TODO: Aggiungi bottoni per far vedere i vari stati in base ad un numero per poter scorrere gli aggiornamenti da dentro la scheda stessa

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton
      redirect={ops.previousRoute}
      transform={(data) => ({ ...data, patient: ops.currentpatient })}
    />
    <DeleteButton undoable={true} />
  </Toolbar>
);

export const UpdateCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm toolbar={<CustomToolbar />} submitOnEnter={false}>
        <FormTab label="Aggiornamenti">
          <TextInput
            fullWidth
            multiline
            line
            source="psychiatricUpdates"
            label="Aggiornamenti Psichiatrici"
          />
          <TextInput
            multiline
            fullWidth
            source="diagnosysUpdate"
            label="Update della Terapia"
          />
          <TextInput
            fullWidth
            multiline
            source="medicalUpdates"
            label="Agg. Medici"
          />
          <TextInput fullWidth multiline source="dreams" label="Sogni" />
          <TextInput
            fullWidth
            multiline
            source="newPerceptions"
            label="Nuove Percezioni"
          />
          <TextInput
            fullWidth
            multiline
            source="OtherTherapeuticUpdates"
            label="Altri Agg."
          />
        </FormTab>
        <FormTab label="Note ed Info">
          <BooleanInput source="certificateRequested" />
          <RichTextInput source="notes" />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default withRouter(UpdateCreate);

// const [updates, setUpdates] = useState([]);

// useEffect(() => {
//   async function GetUpdates() {
//     let up = await ops.getUpdatesForPatient(ops.currentpatient);
//     if (up && up.length >= 1) {
//       setUpdates(up[up.length - 1]);
//       // console.log(up[up.length - 1]);
//     }
//   }

//   GetUpdates();
// }, []);
