import * as React from "react";
import { Box, Paper, List, ListItem, ListItemText } from "@material-ui/core";

import { withRouter } from "react-router-dom";

import ops from "../operations";

const UpdatesList = (props) => {
  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <Paper elevation={3} style={{ padding: ".5em" }}>
        <List>
          {props.updates.map((update, index) => (
            <ListItem disablePadding key={index}>
              <ListItem
                selected={Number(update.id) == Number(props.selected)}
                button
                onClick={() => {
                  props.history.push("/updates/" + update.id);
                }}
              >
                <ListItemText primary={ops.formatDate(update.created_at)} />
              </ListItem>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default withRouter(UpdatesList);
