import React, { useEffect, useState } from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  RichTextField,
  TextInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  BooleanInput,
  DateField,
} from "react-admin";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { withRouter } from "react-router-dom";

import RichTextInput from "ra-input-rich-text";
// import ReactHtmlParser from "react-html-parser";

import UpdatesList from "../UpdatesList";

import ops from "../../operations";

//va aggiunto un campo in sola lettura della terapia precedente, qualora esistente

const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};

const UpdateDate = ({ record }) => {
  return (
    <span>
      {record ? `Aggiornamento del: ${formatDate(record.created_at)}` : ""}
    </span>
  );
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton redirect={null} />
    <DeleteButton undoable={true} redirect={ops.previousRoute} />
    <Button
      onClick={() =>
        props.props.history.push("/patients/" + ops.currentpatient + "/2")
      }
    >
      Back
    </Button>
  </Toolbar>
);

export const UpdateEdit = (props) => {
  const [updates, setUpdates] = useState([]);

  async function GetUpdates() {
    let up = await ops.getUpdatesForPatient(ops.currentpatient);
    if (up && up.length >= 1) {
      // let thisUpdate = up.findIndex((X) => X.id == props.id);
      // console.log(props.id);
      setUpdates(up.reverse());
    }
    console.log(
      props.location.pathname.split("/")[
        props.location.pathname.split("/").length - 1
      ]
    );
  }

  useEffect(() => {
    GetUpdates();
  }, []);

  return (
    <>
      {updates.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: "10rem",
            left: "1rem",
          }}
        >
          <UpdatesList
            updates={updates}
            selected={
              props.location.pathname.split("/")[
                props.location.pathname.split("/").length - 1
              ]
            }
          />{" "}
        </div>
      )}
      <Edit {...props} title={<UpdateDate />}>
        <TabbedForm
          toolbar={<CustomToolbar props={props} submitOnEnter={false} />}
        >
          <FormTab label="Aggiornamenti">
            <TextInput
              fullWidth
              multiline
              line
              source="psychiatricUpdates"
              label="Aggiornamenti Psichiatrici"
            />
            <TextInput
              multiline
              fullWidth
              source="diagnosysUpdate"
              label="Update della Terapia"
            />
            <TextInput
              fullWidth
              multiline
              source="medicalUpdates"
              label="Agg. Medici"
            />
            <TextInput fullWidth multiline source="dreams" label="Sogni" />
            <TextInput
              fullWidth
              multiline
              source="newPerceptions"
              label="Nuove Percezioni"
            />
            <TextInput
              fullWidth
              multiline
              source="OtherTherapeuticUpdates"
              label="Altri Agg."
            />
          </FormTab>
          <FormTab label="Note ed Info">
            <DateField source="created_at" />
            <BooleanInput source="certificateRequested" />
            <RichTextInput source="notes" />
          </FormTab>
        </TabbedForm>
      </Edit>
    </>
  );
};

export default withRouter(UpdateEdit);

// const [updates, setUpdates] = useState([]);

// async function GetUpdates() {
//   let up = await ops.getUpdatesForPatient(ops.currentpatient);
//   if (up && up.length >= 1) {
//     // let thisUpdate = up.findIndex((X) => X.id == props.id);
//     // console.log(props.id);
//     setUpdates(up);
//   }
//   console.log(up);
// }

// useEffect(() => {
//   GetUpdates();
// }, []);

/* <Button
          disabled={
            updates.length > 0 &&
            updates.findIndex((X) => X.id == props.id) == 0
          }
          onClick={async () => {
            if (updates.length == 0) await GetUpdates();
            let thisUpdate = updates.findIndex((X) => X.id == props.id);
            if (thisUpdate - 1 >= 0)
              props.history.push("/updates/" + updates[thisUpdate - 1].id);
          }}
        >
          ◀
        </Button>
        <Button
          disabled={
            updates.length > 0 &&
            updates.findIndex((X) => X.id == props.id) == updates.length - 1
          }
          onClick={() => {
            let thisUpdate = updates.findIndex((X) => X.id == props.id);
            if (thisUpdate + 1 < updates.length)
              props.history.push("/updates/" + updates[thisUpdate + 1].id);
          }}
        >
          ▶
        </Button> */
