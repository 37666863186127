import React from "react";
import { Admin, Resource, fetchUtils, ListGuesser } from "react-admin";
import simpleRestProvider from "ra-strapi-rest";
import { createMuiTheme } from "@material-ui/core/styles";

import ops from "./operations";

//Patients
import PatientsList from "./components/PatientsList";
import PatientEdit from "./components/PatientEdit";
import PatientCreate from "./components/PatientCreate";

//Anamnesys
import AnamnesysEdit from "./components/Anamnesys/AnamnesysEdit";

//Horoscope
import HoroscopeEdit from "./components/Horoscope/HoroscopeEdit";
import HoroscopeCreate from "./components/Horoscope/HoroscopeCreate";

//Updates
import UpdatesEdit from "./components/Updates/UpdatesEdit";
import UpdatesCreate from "./components/Updates/UpdatesCreate";

//Auth
import Cookies from "./helpers/Cookies";
import authProvider from "./AuthProvider";
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = Cookies.getCookie("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(ops.API, httpClient);

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#ffcc80",
    },
    secondary: {
      main: "#ffcc80",
    },
    background: {
      default: "#fff8e1",
      paper: "#fafafa",
    },
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: "#689f38",
        color: "#fff",
      },
    },
  },
  typography: {
    fontFamily: "Lato",
    fontSize: 18,
  },
  props: {
    MuiButton: {
      size: "small",
    },
    MuiButtonGroup: {
      size: "small",
    },
    MuiCheckbox: {
      size: "small",
    },
    MuiFab: {
      size: "small",
    },
    MuiFormControl: {
      margin: "dense",
      size: "small",
    },
    MuiFormHelperText: {
      margin: "dense",
    },
    MuiIconButton: {
      size: "small",
    },
    MuiInputBase: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiRadio: {
      size: "small",
    },
    MuiSwitch: {
      size: "small",
    },
    MuiTextField: {
      margin: "dense",
      size: "small",
    },
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: "small",
    },
  },
});

const App = (props) => (
  <Admin theme={theme} authProvider={authProvider} dataProvider={dataProvider}>
    <Resource
      name="patients"
      list={PatientsList}
      edit={PatientEdit}
      create={PatientCreate}
    />
    <Resource name="anamnesys" edit={AnamnesysEdit} />
    <Resource name="horoscopes" edit={HoroscopeEdit} create={HoroscopeCreate} />
    <Resource name="updates" edit={UpdatesEdit} create={UpdatesCreate} />
  </Admin>
);

export default App;
