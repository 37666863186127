import React from "react";
import {
  ArrayInput,
  ArrayField,
  Datagrid,
  DateTimeInput,
  Create,
  ImageField,
  ImageInput,
  NumberInput,
  RichTextField,
  SimpleForm,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  SingleFieldList,
  TextInput,
  TextField,
} from "react-admin";

import {
  Button,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import RichTextInput from "ra-input-rich-text";

const PatientName = ({ record }) => {
  return (
    <span>
      {record.nome
        ? `Scheda paziente: ${record.nome} ${record.cognome}`
        : "Nuovo Paziente"}
    </span>
  );
};

const PatientCreate = (props) => {
  console.log(props);
  return (
    <Create undoable {...props} title={<PatientName />}>
      <TabbedForm redirect="edit" submitOnEnter={false}>
        <FormTab label="Anagrafiche">
          <Paper
            elevation={2}
            style={{
              margin: "1rem",
              padding: "2rem",
              width: "calc(100% - 6rem)",
              justifyContent: "space-evenly",
            }}
          >
            <TextInput source="nome" fullWidth multiline />
            <TextInput source="cognome" fullWidth multiline />
            <DateTimeInput source="birthday" fullWidth />
            <TextInput source="birthPlace" fullWidth multiline />
          </Paper>
        </FormTab>
        <FormTab label="Contatti">
          <Paper
            elevation={2}
            style={{
              margin: "1rem",
              padding: "2rem",
              width: "calc(100% - 6rem)",
              justifyContent: "space-evenly",
            }}
          >
            <TextInput source="address" fullWidth multiline />
            <TextInput source="taxCode" fullWidth />
            <TextInput source="cellphone" fullWidth />
            <TextInput source="sender" fullWidth />
            <TextInput source="homeDoctor" fullWidth />
            {/* <TextInput source="homephone" fullWidth /> */}
            <TextInput source="email" fullWidth />
          </Paper>
        </FormTab>
        <FormTab label="Anamnesi">
          <Paper
            elevation={2}
            style={{
              margin: "1rem",
              padding: "2rem",
              width: "calc(100% - 6rem)",
              justifyContent: "space-evenly",
            }}
          >
            <TextInput fullWidth multiline label="Richiesta" source="request" />
            <TextInput
              fullWidth
              multiline
              label="Anamnesi Familiare"
              source="familiarAn"
            />
            <TextInput
              fullWidth
              multiline
              label="Anamnesi Fisiologica"
              source="physioAn"
            />
            <TextInput
              fullWidth
              multiline
              label="Anamnesi Somatica"
              source="somatizedAn"
            />
            <TextInput
              fullWidth
              multiline
              label="Anamnesi Psichiatrica Remota"
              source="psychiatricAnRemote"
            />

            <TextInput
              fullWidth
              multiline
              label="Anamnesi Psichiatrica Prossima"
              source="psychiatricAnProximal"
            />
            <TextInput
              multiline
              fullWidth
              label="Terapia Attuale"
              source="currentTherapy"
            />

            <TextInput
              fullWidth
              multiline
              label="Percezioni Sottili"
              source="perceptions"
            />

            <TextInput fullWidth multiline label="Note" source="notes" />
          </Paper>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default PatientCreate;
