import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import {
  List,
  DateTimeInput,
  Edit,
  TabbedForm,
  FormTab,
  BooleanField,
  TextInput,
  DateField,
  Toolbar,
  SaveButton,
  DeleteButton,
  ReferenceManyField,
  Datagrid,
  useGetOne,
  useDataProvider,
} from "react-admin";

import { Grid, Paper } from "@material-ui/core";

import ops from "../operations";

const PatientName = ({ record }) => {
  return (
    <span>
      {record ? `Scheda paziente: ${record.nome} ${record.cognome} ` : ""}
    </span>
  );
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton redirect="edit" />
    <DeleteButton undoable={true} />
  </Toolbar>
);

function calculate_age(dob) {
  dob = new Date(dob.dob);
  var diff_ms = Date.now() - new Date(dob).getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

const AgeField = (dob) => <span>{calculate_age(dob)}</span>;

const PatientEdit = (props) => {
  // const [anamnesys, setAnamnesys] = useState(null);

  const dataProvider = useDataProvider();
  const [patients, SetPatients] = useState(0);
  const [patientNumber, SetPatientNumber] = useState(0);

  useEffect(() => {
    // async function GetAnamnesys() {
    //   let an = await ops.getAnamnesysForPatient(props.id);
    //   setAnamnesys(an);
    // }
    async function getPatientsCount() {
      let count = await ops.getPatientsCount();
      let number = await ops.getPatientNumber(props.id);
      SetPatients(count);
      SetPatientNumber(number);
    }

    getPatientsCount();

    // GetAnamnesys();
    ops.previousRoute = props.history.location.pathname;
    ops.currentpatient = props.id;
  }, []);

  //get patient data and store it
  const { data, loading, error } = useGetOne("patients", props.id);

  // if (loading) return <h1>Loading</h1>;

  return (
    <React.Fragment>
      {props.id && (
        <>
          <Paper
            elevation={3}
            style={{
              padding: ".5em",
              position: "absolute",
              top: "8rem",
              left: "1rem",
            }}
          >
            <h2>
              {patientNumber} / {patients > 0 && patients}
            </h2>
            <hr />
            <h3>
              {!loading && (
                <Grid>
                  <Grid item>
                    <span>Creazione: </span>
                    <span>{ops.formatDate(new Date(data.created_at))}</span>
                  </Grid>
                  <Grid item>
                    <span>Anni: </span>
                    <AgeField dob={data.birthday} style={{ padding: "1rem" }} />
                  </Grid>
                </Grid>
              )}
            </h3>
          </Paper>
        </>
      )}
      <Edit undoable {...props} title={<PatientName />}>
        <TabbedForm
          toolbar={<CustomToolbar />}
          warnWhenUnsavedChanges
          submitOnEnter={false}
        >
          <FormTab label="Anagrafiche e Diagnosi">
            <Grid
              container
              justify="space-around"
              style={{ width: "100%", height: "2rem" }}
            ></Grid>
            <Grid container justify="space-around" style={{ width: "100%" }}>
              <Grid item>
                <TextInput source="nome" />
              </Grid>
              <Grid item>
                <TextInput source="cognome" />
              </Grid>
              <Grid item>
                <DateTimeInput
                  source="birthday"
                  label="Data ed ora di nascita"
                />
              </Grid>
              <Grid item>
                <TextInput
                  source="birthPlace"
                  label="Luogo di nascita"
                  multiline
                />
              </Grid>
            </Grid>

            <Grid container justify="space-around" style={{ width: "100%" }}>
              <Grid item xs={5}>
                <TextInput
                  source="address"
                  fullWidth
                  label="Indirizzo"
                  multiline
                />
              </Grid>
              <Grid item xs={5}>
                <TextInput source="taxCode" fullWidth label="C. Fiscale" />
              </Grid>
            </Grid>

            <Grid
              container
              justify="space-around"
              fullWidth
              style={{ width: "100%" }}
            >
              <Grid item xs={5}>
                <TextInput source="cellphone" fullWidth label="Cellulare" />
              </Grid>
              <Grid item xs={5}>
                <TextInput source="email" fullWidth />
              </Grid>
            </Grid>

            <Grid
              container
              justify="space-around"
              fullWidth
              style={{ width: "100%" }}
            >
              <Grid item xs={5}>
                <TextInput source="sender" fullWidth label="Inviante" />
              </Grid>
              <Grid item xs={5}>
                <TextInput
                  source="homeDoctor"
                  fullWidth
                  label="Medico di famiglia"
                />
              </Grid>
            </Grid>

            <TextInput
              multiline
              source="presentingSymptom"
              fullWidth
              label="Sintomi presentati"
            />

            <TextInput fullWidth multiline label="Richiesta" source="request" />

            <TextInput
              multiline
              source="diagnosys"
              fullWidth
              label="Diagnosi"
            />
          </FormTab>
          {/* <FormTab label="Info Mediche">
          <Paper
          elevation={2}
            style={{
              margin: "1rem",
              padding: "2rem",
              width: "calc(100% - 6rem)",
            }}
            >
            <h3>Sintomi</h3>
            <RichTextInput source="symptoms" label={null} />
          </Paper>
        </FormTab> */}
          <FormTab label="Anamnesi">
            {/* <DateField source="published_at" />
          <DateField source="created_at" />
          <DateField source="updated_at" /> */}

            <TextInput
              fullWidth
              multiline
              label="Anamnesi Familiare"
              source="familiarAn"
            />
            <TextInput
              fullWidth
              multiline
              label="Anamnesi Fisiologica"
              source="physioAn"
            />
            <TextInput
              fullWidth
              multiline
              label="Anamnesi Somatica"
              source="somatizedAn"
            />
            <TextInput
              fullWidth
              multiline
              label="Anamnesi Psichiatrica Remota"
              source="psychiatricAnRemote"
            />

            <TextInput
              fullWidth
              multiline
              label="Anamnesi Psichiatrica Prossima"
              source="psychiatricAnProximal"
            />
            <TextInput
              multiline
              fullWidth
              label="Terapia Attuale"
              source="currentTherapy"
            />

            <TextInput
              fullWidth
              multiline
              label="Percezioni Sottili"
              source="perceptions"
            />

            <TextInput fullWidth multiline label="Note" source="notes" />
          </FormTab>

          <FormTab label="Aggiornamenti">
            {/* <ReferenceManyField
            label="Oroscopi"
            reference="horoscopes"
            target="patient"
          >
          <List>
              <Datagrid rowClick="edit">
              <TextField source="id" />
              </Datagrid>
              </List>
          </ReferenceManyField> */}
            <ReferenceManyField
              label="Updates"
              reference="updates"
              target="patient"
              sortBy="created_at"
              filter={{ patient: props.id }}
              fullWidth
            >
              <List {...props}>
                <Datagrid
                  rowClick={(id) => {
                    ops.previousRoute = props.history.location.pathname;
                    props.history.push("/updates/" + id);
                  }}
                >
                  <DateField
                    source="created_at"
                    label="Data Aggiornamento"
                    locales="fr-FR"
                  />
                  <BooleanField
                    source="certificateRequested"
                    label="Richesto Certificato"
                  />
                </Datagrid>
              </List>
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>
      </Edit>
    </React.Fragment>
  );
};

export default withRouter(PatientEdit);
