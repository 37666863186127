import React, { useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  ImageInput,
  ImageField,
  TextField,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";

import RichTextInput from "ra-input-rich-text";

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton redirect="edit" />
    <DeleteButton undoable={true} />
  </Toolbar>
);

export const HoroscopeEdit = (props) => {
  const [url, setUrl] = useState("http://localhost:1337");
  useEffect(() => {
    fetch(url + props.match.url)
      .then((response) => response.json())
      .then((data) => {
        setUrl(url + data.horoscope[0].url);
      });
  }, []);

  useEffect(() => {
    console.log(url);
  }, [url]);

  return (
    <Edit {...props} toolbar={<CustomToolbar />}>
      <SimpleForm>
        <RichTextInput label="Note" source="notes" />
        <ImageInput label="Oroscopo" accept="image/*">
          <img
            src={url}
            style={{ width: "100%", objectFit: "contain", height: "auto" }}
          />
        </ImageInput>
        <TextField source="published_at" />
        <TextField source="created_at" />
        <TextField source="updated_at" />
      </SimpleForm>
    </Edit>
  );
};

export default HoroscopeEdit;
