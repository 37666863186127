import axios from "axios";
import Cookies from "./helpers/Cookies";

class Operations {
  constructor() {
    this.API = "https://api.mypatients.page";
    this.previousRoute = null;
    this.currentpatient = null;

    // Add a request interceptor
    axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        const token = Cookies.getCookie("token");
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }

  getPatientNumber = async (patientID) => {
    try {
      let res = await axios.get(this.API + "/patients/");
      let num = res.data.findIndex((x) => x.id == patientID);
      return num;
    } catch (error) {
      console.log("GET PATIENTS COUNT", error);
      return null;
    }
  };

  getPatientsCount = async () => {
    try {
      let res = await axios.get(this.API + "/patients/count/");
      return res.data;
    } catch (error) {
      console.log("GET PATIENTS COUNT", error);
      return null;
    }
  };

  getAnamnesysForPatient = async (patientID) => {
    try {
      let res = await axios.get(this.API + "/patientanamnesys/" + patientID);
      return res.data;
    } catch (error) {
      console.log("GET ANAMNESYS", error);
      return null;
    }
  };

  getUpdatesForPatient = async (patientID) => {
    try {
      let res = await axios.get(this.API + "/patientupdates/" + patientID);
      return res.data;
    } catch (error) {
      console.log("GET UPDATES", error);
      return null;
    }
  };

  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  };
}

String.prototype.trimTo = function (amount) {
  let str = this.valueOf();
  let trimmed = "";
  if (str.length >= amount - 3) trimmed = str.substring(0, amount - 3) + "...";
  else trimmed = str.substring(0, amount);
  return trimmed;
};

const ops = new Operations();
export default ops;
